import Vue from "vue";
import Loader from "@/components/misc/loader.vue";
import PopupWrap from "@/components/misc/popup-wrap.vue";
import MainButton from "@/components/@core/MainButton/index.vue";
import autocomplete from "@/components/autocomplete/index.vue";
import smallLoader from "@/components/misc/small-loader.vue";
import customBreadcrumbs from "@/components/custom-breadcrumbs/index.vue";
import InstaVue from "insta-vue";

Vue.component("insta-vue", InstaVue);
Vue.component("small-loader", smallLoader);
Vue.component("loader", Loader);
Vue.component("custom-breadcrumbs", customBreadcrumbs);
Vue.component("popup-wrap", PopupWrap);
// #todo remove legacy inject after replace in project
Vue.component("main-button", MainButton);
Vue.component("MainButton", MainButton);
Vue.component("autocomplete", autocomplete);
Vue.component("v-pagination", window["vue-plain-pagination"]);
