import {VueSlideToggle} from "vue-slide-toggle";
import {isClickableInSidebarMenu, isProcessableItem, routeForMenuItem} from "@/helpers";

export default {
  name: "BurgerNav",
  components: {
    VueSlideToggle,
  },
  props: {
    info: {
      type: Object,
      default: () => {
      },
    },
  },
  created() {
  },
  data() {
    return {
      open: false,
      openSubMenu: false,
    };
  },
  methods: {
    isClickableInSidebarMenu: isClickableInSidebarMenu,
    isProcessableItem: isProcessableItem,
    /**
     * @param {NavigationItem} navItem
     * @param {NavigationItem|null} parentNavItem
     * @return {Object}
     */
    routeForMenuItem(navItem, parentNavItem = null) {
      //   #todo check if this slug are not empty
      if (navItem.slug === "skinon-vs-acne") {
        return this.$localize({name: 'forum'});
      }
      return this.$localize(routeForMenuItem(navItem, parentNavItem));
    },
  },
};
