








































import { mapActions, mapGetters, mapMutations } from 'vuex';
import Hidden from '@/components/@general/Hidden/index.vue';
import Header from '@/components/@general/Header/index.vue';
import Footer from '@/components/@general/Footer/index.vue';
import { isIPad, isMobile } from '@/helpers/variables';

import { defineComponent } from 'vue';
import IconScrollTop from '@/components/icons/IconScrollTop.vue';


export default defineComponent({
  name: 'app',
  data() {
    return {
      isShowSocials: false
    };
  },
  components: {
    IconScrollTop,
    Hidden,
    Header,
    Footer
  },
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      variables: `setting/variables`,
      globalLoader: 'system/globalLoader'
    })
  },
  created() {
    if (this.isAuthenticated) {
      this.fetchUser();
      this.getFavorites();
    }
    this.getVariables();
  },
  mounted() {
    if (!isMobile.any()) {
      document.querySelector('body')?.classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body')?.classList.add('iPad');
    }
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      fetchUser: 'profile/FETCH_USER_DATA',
      getFavorites: 'favorites/GET_FAVORITES_LIST',
      getVariables: 'setting/GET_VARIABLES'
    }),
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    showSocials() {
      this.isShowSocials = !this.isShowSocials;
    }
  }
});
