import {$http} from '@/utils/https'
import type {ActionContext} from "vuex";
import type {RootState} from "@/store";
// @ts-ignore
import * as querystring from "querystring";

interface State {
  ingredientList: any;
  ingredientListLoading: boolean;
  ingredientProductsList: any;
  ingredientProductsListLoading: boolean;
  ingredientData: any;
  ingredientDataLoading: boolean;
}

const state: State = {
  ingredientList: null,
  ingredientListLoading: false,
  ingredientProductsList: null,
  ingredientProductsListLoading: false,
  ingredientData: null,
  ingredientDataLoading: false,
};

const getters = {
  ingredientList: (state: State) => state.ingredientList,
  ingredientListLoading: (state: State) => state.ingredientListLoading,
  ingredientProductsList: (state: State) => state.ingredientProductsList,
  ingredientProductsListLoading: (state: State) => state.ingredientProductsListLoading,
  ingredientData: (state: State) => state.ingredientData,
  ingredientDataLoading: (state: State) => state.ingredientDataLoading,
};

const actions = {
  GET_INGREDIENT_LIST: async ({commit}: ActionContext<State, RootState>, payload: any) => {
    commit('CHANGE_INGREDIENT_LIST_LOADING', true);
    try {
      const response = await $http.get<{ data: any }>(`v1/ingredients?${querystring.stringify(payload)}`);
      commit('SET_INGREDIENT_LIST', response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_INGREDIENT_LIST_LOADING', false);
    }
  },
  GET_INGREDIENT_PRODUCTS_LIST: async ({commit}: ActionContext<State, RootState>, {slug, payload}: any) => {
    commit('CHANGE_INGREDIENT_PRODUCTS_LIST_LOADING', true);
    try {
      const response = await $http.get<{ data: any }>(`v1/ingredients/products/${slug}?${querystring.stringify(payload)}`);
      commit('SET_INGREDIENT_PRODUCTS_LIST', response.data);
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_INGREDIENT_PRODUCTS_LIST_LOADING', false);
    }
  },
  GET_INGREDIENT_DATA: async ({commit}: ActionContext<State, RootState>, slug: any) => {
    commit('CHANGE_INGREDIENT_DATA_LOADING', true);
    try {
      const response = await $http.get<{ data: any }>(`v1/ingredients/${slug}`);
      commit('SET_INGREDIENT_DATA', response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit('CHANGE_INGREDIENT_DATA_LOADING', false);
    }
  },
}

const mutations = {
  SET_INGREDIENT_LIST(state: State, data: any) {
    state.ingredientList = data;
  },
  CHANGE_INGREDIENT_LIST_LOADING(state: State, status: boolean) {
    state.ingredientListLoading = status;
  },
  SET_INGREDIENT_PRODUCTS_LIST(state: State, data: any) {
    state.ingredientProductsList = data;
  },
  CHANGE_INGREDIENT_PRODUCTS_LIST_LOADING(state: State, status: boolean) {
    state.ingredientProductsListLoading = status;
  },
  SET_INGREDIENT_DATA(state: State, data: any) {
    state.ingredientData = data;
  },
  CHANGE_INGREDIENT_DATA_LOADING(state: State, status: boolean) {
    state.ingredientDataLoading = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
