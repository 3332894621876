import Vue from "vue";
import VueI18nManager from "vue-i18n-manager";
import store from "@/store";
import router from "@/router";
import { translation } from "@/helpers/translation";

const proxy = {
    getTranslation: async () => {
        try {
            const response = await store.dispatch(`setting/GET_TRANSLATIONS`);
            Vue.use(response, translation);
            return response;
        } catch (e) {
            console.error(e);
            return translation;
        }
    },
};

Vue.use(VueI18nManager, {
    store,
    router,
    proxy,
    config: {
        defaultCode: "ua-UA",
        languages: [
            {
                name: "Ukranian",
                title: "UA",
                key: "uk",
                code: "ua-UA",
                urlPrefix: "ua",
                translationKey: "ua",
            },
            {
                name: "Russian",
                title: "Ru",
                key: "ru",
                code: "ru-RU",
                urlPrefix: "ru",
                translationKey: "ru",
            },
        ],
        translations: proxy,
    },
});
