import {mapActions, mapGetters, mapMutations} from "vuex";
import navigateTo from "../../../mixins/buy-flow";
import _ from "lodash";
import {directive as onClickaway} from "vue-clickaway";
import BurgerNav from "@/components/@general/BurgerNav/index.vue";
import AdminBar from "@/components/admin-utils/admin-bar/index.vue";
import {isClickableInMainMenu, isProcessableItem, routeForMenuItem, routeToProduct} from "@/helpers";
import IconFacebook from '@/components/icons/IconFacebook.vue';
import IconInstagram from '@/components/icons/IconInstagram.vue';
import IconFacebookFill from '@/components/icons/IconFacebookFill.vue';

export default {
  name: 'Header',
  mixins: [navigateTo],
  directives: {
    onClickaway: onClickaway
  },
  components: {
    IconFacebookFill,
    IconInstagram,
    IconFacebook,
    AdminBar,
    BurgerNav
  },

  data() {
    return {
      querySearch: '',
      show: false,
      openBurger: false,
      showSearch: false,
      windowWidth: window.innerWidth <= 980,
      headerMessages: [],
      searchLoading: false,
      searchResultData: { news: [], products: [] }
    };
  },
  watch: {
    $route() {
      this.openBurger = false;
    },
    openBurger() {
      if (this.openBurger === true) {
        document.getElementById('html').classList.add('hide');
      } else {
        document.getElementById('html').classList.remove('hide');
      }
    },
    isProfessional: function () {
      this.fetchHeaderMenu();
    }
  },
  created() {
    this.fetchHeaderMenu();

    // window.addEventListener("resize", this.myEventHandler);
  },
  mounted() {
    this.getHeaderMessages().then((headerMessages) => {
      this.headerMessages = headerMessages;
      window.setInterval(() => {
        this.pollHeaders();
      }, 5000);
    });
  },
  computed: {
    ...mapGetters(['languages', 'currentLanguage']),
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      basket: 'basket/basket',
      user: 'profile/user',
      isDistributor: 'profile/isDistributor',
      isManager: 'profile/isManager',
      isProfessional: 'profile/isProfessional',
      isUserWithCode: 'profile/isUserWithCode',
      headerMenu: 'setting/headerMenu',
      whichList: 'favorites/whichList',
      contacts: 'setting/variables'
    })
  },
  methods: {
    isClickableInMainMenu: isClickableInMainMenu,
    routeToProduct: routeToProduct,
    ...mapMutations({
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP'
    }),
    ...mapActions({
      fetchSearchItems: 'search/GET_SEARCH_LIST',
      fetchHeaderMenu: 'setting/GET_HEADER_MENU',
      getHeaderMessages: 'setting/GET_HEADER_MESSAGES'
    }),
    async fetchResults() {
      this.searchLoading = true;
      this.searchResultData = await this.fetchSearchItems(this.querySearch);
      this.searchLoading = false;
    },
    sendRequest: _.debounce(function () {
      if (this.querySearch.length > 2) {
        this.fetchResults();
      }
    }, 500),
    isProcessableItem: isProcessableItem,
    pollHeaders() {
      const first = this.headerMessages.shift();
      this.headerMessages = this.headerMessages.concat(first);
    },
    /**
     * @param {NavigationItem} navItem
     * @param {NavigationItem|null} parentNavItem
     * @return {Object}
     */
    routeForMenuItem(navItem, parentNavItem = null) {
      if (navItem.slug === 'skinon-vs-acne') {
        return this.$localize({ name: 'forum' });
      }
      return this.$localize(routeForMenuItem(navItem, parentNavItem));
    },
    redirectToSearchPage() {
      this.setFocusField(false);
      // setTimeout(() => {
      this.$router.push({ name: 'result', query: { search: this.querySearch } });
      // }, 500)
    },
    myEventHandler() {
      if (window.innerWidth <= 980) {
        this.showSearch = !this.showSearch;
      }
    },

    showPopup() {
      if (!this.isAuthenticated) {
        this.show = false;
        this.openBurger = false;
        this.changeLoginPopup(true);
      } else {
        this.navigateTo('profile');
      }
    },
    setFocusField(status) {
      this.show = status;
    },
    changeSearch() {
      this.sendRequest();
    },
    changeLanguage(code) {
      this.showLangList = false;
      this.$setLanguage(code).then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 900);
      });
    },
    away: function () {
      this.querySearch = '';
      this.showSearch = false;
    },
    /**
     * @param {keyof GeneralContacts|string} field
     * @return {string}
     */
    getContact(field) {
      return this.contacts?.contacts[field] || '';
    }
  },

  destroyed() {
    // window.removeEventListener("resize", this.myEventHandler);
  }
};
