import {storage} from "@/utils/storage";

const storeKey = "admin_bar_isOpen";

const setStateToLocalStore = state => {
  storage.setItem(storeKey, state ? "1" : "0");

  return state;
};

const getStoredStatus = () => {
  return storage.hasOwnProperty(storeKey) ? !!storage.getItem(storeKey) : false;
};

export default {
  name: "container",
  components: {},
  props: {
    visible: Boolean,
  },
  data() {
    return {
      isOpen: getStoredStatus(),
    };
  },
  methods: {
    showHeader() {
      this.isOpen = setStateToLocalStore(true);
    },
    hideHeader() {
      this.isOpen = setStateToLocalStore(false);
    },
  },
};
