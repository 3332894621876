const storagePrefix = `APP_${process.env.VUE_APP_NAME}`;
export const storage = {
  setItem: (key, value) => {
    localStorage.setItem(`${storagePrefix}_${key}`, value);
  },
  getItem: (key) => {
    return localStorage.getItem(`${storagePrefix}_${key}`);
  },
  removeItem: (key) => {
    if (this.getItem(key)) {
      localStorage.removeItem(`${storagePrefix}_${key}`);
    }
  },
  removeItems: (keys) => {
    for (let key of keys) {
      this.removeItem(key);
    }
  }
};
