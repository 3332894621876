import {$http} from '@/utils/https'
import type {InstagramPost} from "@/types/site";
import type {Product} from "@/types/shop";

interface State {
}

const state: State = {};

const getters = {};

const actions = {
  GET_HOME_BANNERS: async () => {
    try {
      const response = await $http.get<{ data: Array<Product> }>(`v1/home/banners`);

      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_PAGE: async () => {
    try {
      const response = await $http.get<{ data: Array<Product> }>(`v1/home/page`);

      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_SERIES: async () => {
    try {
      const response = await $http.get<{ data: Array<Product> }>(`v1/home/series`);

      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_NEW: async () => {
    try {
      const response = await $http.get<{ data: Array<Product> }>(`v1/home/new`);

      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_TOP: async () => {
    try {
      const response = await $http.get<{ data: Array<Product> }>(`v1/home/top`);

      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_NEWS: async () => {
    try {
      const response = await $http.get<{ data: Array<Product> }>(`v1/home/news`);

      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  GET_HOME_INSTAGRAM_POSTS: async () => {
    try {
      const response = await $http.get<{ data: Array<Product> }>(`v1/home/instagram_posts`);

      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  //---#todo legacy, ask manager
  INSTAGRAM_POSTS: async () => {
    try {
      const response = await $http.get<{ data: Array<InstagramPost> }>(`v1/instagram_posts`);

      return response.data.data;
    } catch (e) {
      throw e;
    }
  },
  //---#todo legacy
  GET_INSTAGRAM_POSTERS: async () => {
    try {
      await $http.get(`https://graph.instagram.com/me/media?fields=id,caption,media_url&access_token=${process.env.VUE_APP_INSTAGRAM_API_KEY}`);
    } catch (e) {
      throw e;
    }
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
